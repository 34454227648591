.productCard {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1em;
  height: 12em;
  border-radius: 12px;
  margin: 0em auto;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.productCard:hover {
  transform: scale(1.02);
}

.productImage {
  width: 12em;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  padding: 0 0em;
}

.productImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.productDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  height: 80%;
  width: 80%;
}

.productName {
  font-size: 0.9em;
  font-weight: 800;
  text-transform: uppercase;
  margin-left: 1.3em;
}

.productPrice {
  font-size: 0.9em;
  font-weight: 600;
  margin-left: 1.3em;
  align-items: flex-end;
}

.icon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  font-size: 0.8em;
  color: #39ca5a;
  transition: all 0.3s ease-in-out;
  width: 8em;
}

.icon span {
  color: #39ca5a;
}

.icon img {
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.5em;
}

.productDetails button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2em;
  border: none;
  border-radius: 3px;
  width: 15em;
  font-size: 0.7em;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  margin: 0 0 0 1.6em;
  background-color: #151515;
  transition: background-color 0.25s ease-out;
}

.discount {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2em;
  border: none;
  border-radius: 3px;
  width: 15em;
  font-size: 0.7em;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  margin: 0 0 0 1.6em;
  background-color: #151515;
  transition: background-color 0.25s ease-out;
  background-color: #39ca5a;
}

.productDetails button:hover {
  background-color: #39ca5a;
}

@media screen and (min-width: 600px) {
  .productCard {
    margin-bottom: 1em;
  }
}

@media screen and (min-width: 890px) {
  .productCard {
    width: 100%;
  }

  .productDetails:first-child {
    padding-right: 1em;
  }
}

@media screen and (min-width: 1100px) {
  .productCard {
    width: 45%;
    margin: 1em 0em;
  }

  .productDetails:first-child {
    padding-right: 1em;
  }
}
