.container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 97%;
  margin: auto;
  height: 90%;
  /* background-color: #f5f5f5; */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.container h3 {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 1em;
  height: 2em;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 1em;
}

.form label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.form input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 1em;
  font-size: 0.9em;
}

.form select {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 1em;
}

.button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: rgb(116, 216, 255);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1em;
}

@media screen and (min-width: 768px) {
  .container {
    width: 70%;
  }
}

@media screen and (min-width: 960px) {
  .container {
    width: 50%;
  }
}
