.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20em;
  height: 95%;
}

.imgContainer {
  height: 100%;
  width: 100%;
}

.imgContainer img {
  height: 17em;
  width: auto;
  object-fit: contain;
}

.priceDiv {
  display: flex;
  align-items: center;
  color: white;
  margin-top: 1em;
  padding: 0.3em 2em;
  background-color: #000000;
  border-radius: 24px;
}

.priceDiv span {
  font-size: 0.8em;
  font-weight: 700;
}
