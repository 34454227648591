.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  margin: 0 auto;
  height: 3.5em;
}

.menuContainer {
  display: flex;
  font-size: 0.9em;
  justify-content: center;
  align-items: center;
  padding: 0.4em 1em;
  border: solid 1px black;
  background-color: black;
  border-radius: 3px;
  margin: 0 auto 0 0;
  cursor: pointer;
}

.path {
  font-size: 0.7em;
  text-transform: uppercase;
  font-weight: 700;
  color: #ffffff;
}

.menu {
  display: flex;
  justify-content: space-between;
  padding: 0.2em 3em;
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  width: 97%;
  margin: 0 auto;
  height: 2.7em;
  position: absolute;
  top: 4em;
  border-radius: 4px;
  overflow: auto !important;
  color: white;
  z-index: 999;
  background-color: black;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.menuItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em 1em;
  margin: 0 1em;
  font-size: 0.9em;
  border-radius: 2px;
  margin: 0;
  cursor: pointer;
}

.menuItem:hover {
  background-color: #ff0d44;
}
