.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 97%;
  min-height: 5em;
  margin: 0.2em auto;
  border-bottom: solid 1px rgb(211, 211, 211);
  position: relative;
}

.orderDot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: rgb(255, 211, 13);
  margin: 0 0.5em 0 0;
}

.chip {
  display: flex;
  align-items: center;
  background-color: rgb(255, 237, 159);
  width: auto;
  padding: 0.2em 1em;
  border-radius: 24px;
}

.chip span[name="status"] {
  font-size: 0.7em;
  font-weight: 500;
  color: rgb(115, 94, 0);
}

.orderTab {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 0.8em;
  justify-content: center;
  align-items: flex-start;
  width: 10em;
  margin: auto;
}

.orderTab span:last-child {
  font-size: 0.75em;
  font-weight: 500;
  color: rgb(161, 161, 161);
  margin-top: 0.5em;
}

.dots {
  display: flex;
  flex-direction: row;
  width: 2em;
  height: 2em;
  border-radius: 4px;
  padding: 0.5em 0.5em;
}

.dots:hover {
  padding: 0.5em;
  border: solid 1px rgb(230, 230, 230);
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 28em;
  padding: 0 4em 0 0;
  height: 70%;
  border-radius: 4px;
  margin: 0 0 0 1em;
  background-color: white;
  position: absolute;
  right: 0;
  z-index: 1;
  font-size: 0.8em;
  font-weight: 700;
  color: black;
  border: solid 1px rgb(230, 230, 230);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  overflow: auto !important;
}

.action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3em 1em;
  border-radius: 4px;
  cursor: pointer;
}

.action:last-child {
  margin-right: 2em;
}

.action:hover {
  background-color: #39ca5a;
  color: white;
}

.action:last-of-type:hover {
  background-color: #ff0e56;
  color: white;
}

.container > img {
  width: 4em;
  height: 4em;
  border-radius: 4px;
  margin: 0 2em 0 0;
}
