.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;
  margin: 1em auto 1em;
}

.profileCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5em;
  margin: 0.5em 0;
  padding: 0.7em;
  border-radius: 0.5em;
}

.profileCard:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.profileCardImg {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-color: #151515;
  color: white;
}

.profileCardInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  font-weight: 600;
  margin-left: 1em;
}

.profileCardInfo span:last-child {
  font-weight: 300;
  color: grey;
  font-size: 0.8em;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1.5em auto 1em;
  font-weight: 600;
}

.personalDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0em 0 1em;
}

.personalDetails input {
  width: 100%;
  height: 3em;
  margin: 0em 0 0.5em;
  padding: 0.5em 1em;
  border: none;
  border-radius: 3px;
  border: solid 1px #f5f5f5;
  font-size: 0.9em;
  color: #606060;
}

.personalDetails input:focus {
  outline: none;
}

.personalDetails input::placeholder {
  font-weight: 300;
  font-size: 0.8em;
  color: grey;
}

.personalDetails button {
  width: 8em;
  height: 2.5em;
  margin: 0.5em 0;
  border: none;
  border-radius: 3px;
  font-size: 0.8em;
  background-color: #151515;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.personalDetails button:last-child {
  margin-bottom: 1em;
}

.edit {
  color: #151515;
  font-size: 0.8em;
  text-decoration: underline;
  cursor: pointer;
  margin-left: auto;
}

.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 2.8em;
  border: none;
  background-color: #151515;
  color: white;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  border-radius: 4px;
}

.logout button {
  font-weight: 700;
  border-radius: 3px;
  font-size: 0.9em;
  margin: 1em 0;
}

.formInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1em 0 1em;
}

.formInput input {
  width: 100%;
  height: 3em;
  margin: 0em 0 2em;
  padding: 0.5em 1em;
  border: none;
  border-radius: 3px;
  border: solid 1px #f5f5f5;
  font-size: 0.85em;
  color: #606060;
}

.formInput label {
  font-weight: 500;
}

.formInput input:focus {
  outline: none;
}

.formInput input::placeholder {
  font-weight: 300;
  font-size: 0.8em;
  color: grey;
}

.formInput input[name="postcode"] {
  margin-bottom: 1em;
}

.checkDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 5em;
  /* margin: 0.5em 0; */
}

.checkDiv input {
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  margin-bottom: 0;
}

.checkDiv label {
  font-size: 0.8em;
  color: #787878;
}
