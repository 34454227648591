.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5em auto 2em;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 97%;
}

.dots {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1.5em;
  padding: 0.2em;
  border: solid 1.5px #d7d5d7;
  border-radius: 3px;
}

.headerBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  margin-top: 1em;
}

.orderNumber {
  font-size: 0.9em;
  font-weight: 700;
}

.orderDot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: rgb(255, 211, 13);
  margin: 0 0.5em 0 0;
}

.chip {
  display: flex;
  align-items: center;
  background-color: rgb(255, 237, 159);
  width: auto;
  padding: 0.2em 1em;
  margin-left: auto;
  height: 1.5em;
  border-radius: 24px;
}

.chip span[name="status"] {
  font-size: 0.7em;
  font-weight: 500;
  color: rgb(115, 94, 0);
}

.orderDate {
  display: flex;
  gap: 0.5em;
  font-size: 0.8em;
  font-weight: 500;
  color: rgb(161, 161, 161);
  margin-top: 0.3em;
}

.orderDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1em;
  border-radius: 4px;
  /* overflow-y: scroll !important; */
  /* max-height: 24em; */
  height: auto;
}

.orderDetailsBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: scroll !important;
  height: 22em;
  font-weight: 700;
  margin: 1.5em auto 0;
  width: 100%;
  padding: 0 0.5em;
}

.orderDetailsLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
}

.orderDetailsBottomLeftContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.8em;
  margin-top: 1em;
  gap: 0.4em;
  font-weight: 500;
  max-height: 10em;
  height: 10em;
}

.orderDetailsRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  max-height: 20em;
}

.orderDetailsBottomRightContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 0.8em;
  margin-top: 1em;
  gap: 0.4em;
  font-weight: 500;
  max-height: 10em;
  height: 10em;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 28em;
  padding: 0 4em 0 0;
  height: 4em;
  border-radius: 4px;
  margin: 0 0 0 1em;
  background-color: white;
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 1;
  font-size: 0.8em;
  font-weight: 700;
  color: black;
  border: solid 1px rgb(230, 230, 230);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  overflow: auto !important;
}

.action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3em 1em;
  border-radius: 4px;
  cursor: pointer;
}

.action:last-child {
  margin-right: 2em;
}

.action:hover {
  background-color: #39ca5a;
  color: white;
}

.action:last-of-type:hover {
  background-color: #ff0e56;
  color: white;
}
