.expandedContainer {
  display: flex;
  width: 100%;
  border-radius: 4px;
  min-height: 6em;
  margin: 0em auto;
  padding: 0.6em 0em;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content img {
  height: 5em;
  width: auto;
}

.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
}

.center {
  display: flex;
  width: 70%;
  font-weight: 800;
  margin-top: 0.6em;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 90%;
  gap: 1.5em;
  padding-left: 1em;
  text-transform: uppercase;
}

.center span[name="productPrice"] {
  font-size: 0.9em;
  color: #8f8f8f;
  margin: 0.2em 0;
  font-weight: 500;
}
