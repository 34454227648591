.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 97%;
  height: 100vh;
  min-height: 7em;
  height: 7em;
  margin: 0em auto;
  border-bottom: solid 1px rgb(211, 211, 211);
  position: relative;
  color: black;
}

.itemImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5em;
  height: 5em;
  margin: 0 1em 0 0;
}

.itemImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: auto;
  height: 80%;
}

.itemName {
  font-weight: 700;
  font-size: 0.9em;
  margin-bottom: 0.3em;
}

.itemQuantity {
  font-size: 0.8em;
  font-weight: 500;
  color: rgb(161, 161, 161);
}

.itemTotal {
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: space-around;
  height: 80%;
  width: 25%;
  font-size: 0.8em;
  font-weight: 600;
  color: rgb(161, 161, 161);
}

.itemTotalPrice {
  display: flex;
  justify-content: space-between;
  font-size: 0.85em;
  font-weight: 600;
}

.itemTotalPrice:last-child span:last-child {
  color: black;
}

.itemTotalPrice span:last-child {
  color: black;
}
