.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 97%;
  height: 90%;
  padding: 0 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
}

.bodyHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 700;
  margin-top: 1em;
}

.productDetailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1em;
  overflow-y: scroll !important;
}

.productDetailsContainer input {
  width: 100%;
  height: 2.5rem;
  margin-top: 0.5em;
  padding: 0 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
  outline-color: #424242;
  margin-bottom: 2em;
}

.productDetailsContainer label {
  font-size: 0.8rem;
  font-weight: 700;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
}

.footerButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.5rem;
  margin: 1em 0.5em;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
