.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  margin: 0.5em 0;
  min-height: 7em;
  /* border-bottom: 1px solid #ccc; */
}

.container:last-child {
  margin-bottom: 6em;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 7em;
}

.content img {
  height: 5em;
  width: auto;
}

.left {
  width: 7em;
  margin-right: 1em;
}

.left,
.right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.center {
  display: flex;
  width: 40%;
  font-weight: 800;
  margin-top: 0.6em;
  font-size: 0.9em;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  padding-left: 1em;
  margin-right: auto;
  text-transform: uppercase;
}

.right {
  text-align: end;
  flex-direction: column;
  margin-top: 0.6em;
  width: 30%;
}

.center span[name="productPrice"] {
  font-size: 0.9em;
  color: #8f8f8f;
  margin: 0.2em 0;
  font-weight: 500;
}

.quantityContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  width: 100%;
  height: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: rgb(207, 207, 207);
}

.buttonContainer button {
  border-radius: 3px;
  border: solid 1px rgb(216, 216, 216);
  background-color: transparent;
  width: 2em;
  color: rgb(205, 205, 205);
  transition: all 0.1s ease-in;
}

.buttonContainer button:hover {
  background-color: rgb(218, 218, 218);
  color: white;
}

.buttonContainer button:first-child {
  margin-left: 0;
}

.deleteRed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.deleteRed button {
  border-radius: 3px;
  color: rgb(205, 205, 205);
  width: 2em;
  background-color: transparent;
  border: solid 1px rgb(216, 216, 216);
  transition: all 0.1s ease-in;
}

.deleteRed button:last-child:hover {
  background-color: rgb(255, 31, 75);
  border: none;
  color: white;
}

.deleteRed button:first-child {
  margin-left: 0;
}

.deleteRed button:first-child:hover {
  background-color: rgb(218, 218, 218);
  color: white;
}

.buttonContainer span {
  font-size: 0.9em;
  font-weight: 400;
  color: rgb(141, 141, 141);
  margin: 0.7em;
}
.deleteRed span {
  font-size: 0.9em;
  color: rgb(141, 141, 141);
  font-weight: 400;
  margin: 0.7em;
}

@media screen and (min-width: 712px) {
  .content {
    min-height: 9em;
  }

  .container {
    min-height: 9em;
  }

  .content img {
    height: 7em;
    width: auto;
  }

  .left {
    width: 9em;
    margin-right: 1em;
  }
}
