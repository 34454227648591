.inputDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3em;
  width: 100%;
  padding: 0.5em 1em;
  background-color: white;
  margin: 0.5em 0;
  z-index: 1;
  border-radius: 4px;
}

.inputDivSmall {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3em;
  width: 100%;
  padding: 0.5em 1em;
  background-color: white;
  margin: 0.5em 0 0.5em;
  z-index: 1;
  border-radius: 4px;
}

.inputDiv > input {
  margin-left: 1em;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 28em;
  width: 90%;
  border-radius: 6px;
  overflow: hidden !important;
  margin: auto;
  z-index: 999;
}

.form input {
  width: 80%;
  border-radius: 4px;
  border: none;
  height: 60%;
  padding: 1em 0em;
  font-size: 1em;
}

.form input::placeholder {
  color: #b7b7b7;
  font-size: 0.8em;
}

.form input:focus {
  outline: none;
}

.form button {
  min-height: 40px;
  width: 100%;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  color: antiquewhite;
  background-color: #2c2c2c;
  margin-top: 0.5em;
}

.checkDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 1.5em;
  margin: 0.5em 0;
}

.checkDiv input {
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
}

.checkDiv label {
  font-size: 0.8em;
  color: #787878;
}

.personalDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0em 0 1em;
}

.personalDetails input {
  width: 100%;
  height: 3.5em;
  margin: 0.5em 0 0.5em;
  padding: 0.5em 1em;
  border: none;
  border-radius: 3px;
  border: solid 1px #f5f5f5;
  font-size: 0.9em;
  color: #606060;
}

.personalDetails input:focus {
  outline: none;
}

.personalDetails input::placeholder {
  font-weight: 300;
  font-size: 0.8em;
  color: grey;
}

.personalDetails button {
  width: 8em;
  height: 2.5em;
  margin: 0.5em 0;
  border: none;
  border-radius: 3px;
  font-size: 0.8em;
  background-color: #151515;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.personalDetails button:last-child {
  margin-bottom: 1em;
}

@media screen and (min-width: 638px) {
  .form {
    width: 80%;
  }
}

@media screen and (min-width: 800px) {
  .form {
    width: 70%;
  }
}

@media screen and (min-width: 1000px) {
  .form {
    width: 40%;
  }
}
