.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  min-height: 6em;
  background-color: #f5f5f5;
  margin: 1em auto 1em;
  padding: 0.6em 1em;
  position: relative;
}

.orderContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 5em;
}

.orderContent span[name="orderNumber"] {
  font-size: 0.8em;
  font-weight: 600;
  color: #494949;
}

.orderContent span[name="status"] {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  font-weight: 600;
}

.orderContent span[name="orderDate"] {
  font-size: 0.8em;
  font-weight: 400;
  color: #878787;
  text-decoration: underline;
}

.orderContent span[name="orderTotal"] {
  font-size: 0.8em;
  font-weight: 400;
  color: #878787;
  padding: 0.1em 1em;
  background-color: black;
  width: 6em;
  text-align: center;
  color: white;
  border-radius: 24px;
  font-size: 0.7em;
  font-weight: 600;
}

.orderDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: 12em;
  margin-top: 1em;
  overflow-y: scroll !important;
}
