.inputDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3em;
  width: 100%;
  padding: 0.5em;
  background-color: white;
  margin: 0.6em 0;
  z-index: 1;
  border-radius: 4px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  border-radius: 6px;
  margin: auto;
  z-index: 1;
}

.form input {
  width: 80%;
  border-radius: 4px;
  border: none;
  height: 60%;
  padding: 1em 0em;
  font-size: 1em;
  z-index: 1;
}

.form input::placeholder {
  color: #b7b7b7;
  font-size: 0.9em;
}

.form input:focus {
  outline: none;
}

.form button {
  min-height: 40px;
  width: 100%;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  color: antiquewhite;
  background-color: #2c2c2c;
  margin-top: 0.5em;
}

.resetText {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #989898;
  width: 100%;
  margin: 0.5em 0;
  font-size: 0.9em;
  background-color: rgb(255, 247, 237);
  border: none;
  width: 90%;
}

@media screen and (min-width: 638px) {
  .form {
    width: 80%;
  }
}

@media screen and (min-width: 800px) {
  .form {
    width: 70%;
  }
}

@media screen and (min-width: 1000px) {
  .form {
    width: 40%;
  }
}
