* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primaryfont: nato-sans, sans-serif;
}
/* 
body {
  overflow: hidden !important;
} */

.App {
  height: 100dvh;
  width: 100vw;
  /* overflow-y: auto !important; */
}

::-webkit-scrollbar {
  display: none;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}
