.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;
  margin: 1em auto 1em;
}

.accountLink {
  font-size: 0.9em;
  font-weight: 400;
  text-decoration: underline;
  color: #878787;
  cursor: pointer;
}
