.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: auto;
  background-color: #151515;
  border-top: solid 1px rgb(195, 195, 195);
}

.logo {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  width: 100%;
  color: white;
  font-size: 1.2em;
  font-weight: bolder;
  padding: 0.5em 0.5em;
  border-radius: 4px;
  transition: all 0.2s ease-in;
  margin-bottom: 0.5em;
}

.subscribeContainer {
  margin: 1em auto 1em;
  width: 80%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.subscribeContainer span:first-child {
  font-size: 1em;
  font-weight: 600;
  margin: 0.5em auto 0.5em;
}

span[name="delivery"] {
  align-self: center;
  font-size: 0.8em;
  font-weight: 600;
  margin: 0em auto;
  text-align: center;
  color: rgb(221, 221, 221);
}

.textContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
}

.royalMailText {
  display: flex;
  font-size: 0.8em;
  font-weight: 600;
  margin: 0.5em 0 1em 1em;
  color: rgb(180, 180, 180);
}

.subscribeContainer span[name="exclusiveOffer"] {
  align-self: center;
  font-size: 0.9em;
  font-weight: 600;
  margin: 0.5em 0;
  text-align: center;
  color: rgb(221, 221, 221);
}

.subscribeForm input {
  height: 2em;
  width: 100%;
  border: solid 1px rgb(85, 85, 85);
  border-radius: 4px;
  padding: 0.5em 1em;
  margin: 0.3em auto;
  background-color: transparent;
  color: rgb(203, 203, 203);
  font-size: 0.9em;
}

.subscribeForm input:focus {
  outline: none;
}

.copyrightText {
  font-size: 0.7em;
  font-weight: 500;
  margin: 0.5em 0 1em 1em;
  color: rgb(94, 94, 94);
}

.submit {
  height: 2.5em;
  width: 100%;
  border: none;
  border-radius: 4px;
  background-color: rgb(255, 12, 68);
  color: white;
  font-size: 0.8em;
  font-weight: 600;
  margin: 0.5em auto 1em;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.iconsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6em;
  width: 90%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0.5em auto;
}

.emailText {
  font-size: 0.8em;
  font-weight: 600;
  margin: 0.5em 0;
  text-align: center;
  color: rgb(221, 221, 221);
}

@media screen and (min-width: 768px) {
  .subscribeContainer {
    width: 90%;
  }
}

@media screen and (min-width: 1024px) {
  .subscribeContainer {
    width: 80%;
  }
}
