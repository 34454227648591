.container {
  display: flex;
  align-items: center;
  width: 97%;
  flex-direction: column;
  margin: 1em auto;
  position: relative;
}

.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21em;
  width: 100%;
  margin: 0 auto 0em;
  padding: 1em;
  border-radius: 9px;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.1);
}

.imgContainer img {
  height: 100%;
  width: auto;
  object-fit: contain;
  border-radius: 9px;
}

.container h3 {
  width: 100%;
  font-size: 0.9em;
  font-weight: 800;
  text-transform: uppercase;
}

span[name="price"] {
  font-size: 0.8em;
  font-weight: 600;
  padding: 0.1em 2em;
  background-color: black;
  color: white;
  border-radius: 24px;
  align-self: flex-start;
  margin-top: 1em;
}

span[name="name"] {
  font-size: 0.8em;
  align-self: flex-start;
  top: 10px;
  right: 10px;
  margin: auto;
  width: 100%;
  margin-bottom: 0.5em;
  color: grey;
  text-decoration: underline;
}

button[name="cart"] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4em;
  border: none;
  border-radius: 3px;
  width: 97%;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  background-color: rgb(0, 0, 0);
  position: fixed;
  bottom: 5px;
  transition: background-color 1s ease-out;
}

button[name="secondCart"] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4em;
  border: none;
  border-radius: 3px;
  width: 97%;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  background-color: rgb(0, 0, 0);
  transition: background-color 1s ease-out;
  margin-bottom: 3em;
}

.iconsDivContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0em 0;
}

.infoDivContainer {
  display: flex;
  flex-direction: column;
  width: 95vw;
  margin: 0em 0 3em;
}

.icons {
  align-self: flex-start;
  display: flex;
  width: 100%;
  margin: 2em 0;
  flex-direction: column;
}

.infoDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.5em 0;
  font-weight: 600;
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgb(203, 203, 203);
}

span[name="answer"] {
  font-weight: 500;
  font-size: 0.9em;
  margin: 0.3em 0;
}

.icons svg,
.icons img {
  display: block;
  margin: 0.6em 0.2em;
}

.icon {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.9em;
}

.icon span {
  margin-left: 1em;
  color: rgb(93, 93, 93);
}

.topDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0em 0 1em;
}

.rightDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 47%;
  margin: 0em 0 1em;
}

.klarnaContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 48%;
  padding: 0 1em 0 0;
  margin: 0em 0 1em 0em;
  border-radius: 4px;
  height: 3em;
  border: solid 1px rgb(236, 236, 236);
}

.clearpayContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 48%;
  padding: 0 1em 0 0;
  margin: 0em 0 1em;
  border-radius: 4px;
  height: 3em;
  border: solid 1px rgb(236, 236, 236);
}

.payLaterContainer {
  display: none;
}

.clearpayContainer img {
  width: 7em;
  object-fit: contain;
  margin-right: 0.5em;
}

.payLaterIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8em;
  height: 100%;
}

.klarna {
  padding: 0.5em 1em;
  border-radius: 4px;
}

.clearpay {
  border-radius: 4px;
  width: 8.8em;
}

.payLaterText {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 0.8em;
  font-weight: 600;
  color: rgb(27, 27, 27);
}

.alternateInfoContainer {
  display: none;
  margin-top: 1em;
}

@media screen and (min-width: 862px) {
  .imgContainer {
    display: flex;
    align-self: flex-start;
    width: 47%;
    margin: 0;
    margin: 1em;
  }

  .secondCart {
    display: none !important;
  }

  .alternatePrice {
    display: unset;
    font-size: 0.8em;
    font-weight: 600;
    padding: 0.1em 2em;
    background-color: black;
    color: white;
    border-radius: 24px;
    align-self: flex-start;
    margin-top: 1em;
  }

  .priceSpan {
    display: none;
  }

  .primaryInfoContainer {
    display: none;
  }

  .alternateInfoContainer {
    display: flex;
    flex-direction: column;
  }

  .payLaterContainer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  .topDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0em 0 0em;
  }

  .iconsDivContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 3em 0;
    justify-content: space-between;
  }

  .icons {
    flex-direction: row;
    gap: 1em;
    width: 100%;
    margin: auto;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.9em;
  }

  .icon span {
    font-weight: 500;
    color: black;
  }

  .icon img {
    margin-right: 1.5em;
  }

  button[name="cart"] {
    position: unset;
    width: 100%;
  }
}
