.container {
  height: 4em;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.leftDiv {
  width: 25%;
}

.logo {
  width: 60%;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-family: Raleway, sans-serif;
}

.rightDiv {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rightDiv svg {
  display: block;
  cursor: pointer;
}

.rightDiv svg:last-child {
  margin-right: -1.5em;
}

.cartLength {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 35px;
  font-weight: 700;
  right: 0px;
  background-color: #f44336;
  color: white;
  border-radius: 50%;
  font-size: 0.55em;
  width: 2em;
  height: 2em;
}

.basketDiv {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 3.6em;
}

.profileDiv {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 3.6em;
}
