.container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  margin: auto;
  height: 90%;
  padding: 5px;
  border-radius: 10px;
}

.coupons {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.coupon {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 97%;
  margin: 0.5em auto;
  height: 4em;
  padding: 0 1em;
  border-radius: 5px;
  background-color: rgb(21, 21, 21);
  color: white;
}

.coupon:first-child {
  margin-top: 1em;
}

.coupon h4 {
  font-size: 0.8em;
  font-weight: 700;
  margin: 0;
  margin-right: auto;
}

.coupon span {
  font-size: 0.8em;
  font-weight: 700;
  margin: 0;
  margin-right: 1em;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6em;
  height: 2em;
  border-radius: 3px;
  padding: 0.1em 1em;
  font-size: 0.8em;
  font-weight: 700;
  border: none;
  cursor: pointer;
  background-color: white;
  transition: all 0.15s ease-in-out;
}

.btn:hover {
  background-color: rgb(255, 36, 76);
  color: white;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 97%;
  height: 3em;
  margin: auto;
  border-radius: 5px;
}

.header span {
  font-size: 0.8em;
  width: 100%;
  font-weight: 600;
  margin: 0;
  text-decoration: underline;
  color: #42a4ff;
}
