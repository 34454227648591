.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.container {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 1em auto 1em;
  background-color: white;
  border: solid 1px rgb(252, 240, 226);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 6px;
  padding: 1.5em;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.4);
  z-index: 999;
  align-items: center;
  overflow-y: scroll !important;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4em;
  margin: 0em 0;
  border-bottom: solid 2px rgb(33, 33, 33);
}

.header span {
  font-size: 1.5em;
  font-weight: 600;
  margin: 0.5em 0;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow-y: scroll !important;
}

.footer {
  display: flex;
  height: 4em;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
}

button[name="continue"] {
  height: 2.5em;
  width: 90%;
  border-radius: 4px;
  background-color: rgb(0, 0, 0);
  font-size: 1.1em;
  font-weight: 600;
  color: white;
  cursor: pointer;
  border: none;
  margin-top: 0.5em;
}

.cartEmptyText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  font-weight: 600;
  margin: 10em 0;
}

.headerText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 1em;
}

span[name="freeShipping"] {
  display: flex;
  justify-content: center;
  font-size: 0.9em;
  margin: 0;
  color: rgb(54, 54, 54);
}

.cartFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0.5em auto;
  position: absolute;
  bottom: 0px;
  background-color: white;
}

.subTotal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.subTotal span[name="subtotal"] {
  font-size: 0.9em;
  font-weight: 600;
  width: 45%;
  text-align: center;
  color: rgb(115, 115, 115);
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 1px;
  background-color: rgb(196, 196, 196);
  margin: 0.5em 0;
}

@media screen and (min-width: 712px) {
  .header {
    height: 5em;
    width: 90%;
  }

  .container {
    width: 85%;
    height: 95%;
    margin: 0 auto;
    top: 1em;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .body {
    width: 90%;
  }

  .cartFooter {
    width: 90%;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 55%;
    height: 95%;
    margin: 0 auto;
    top: 1em;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .header {
    height: 5em;
    width: 90%;
  }

  .body {
    width: 90%;
  }

  .cartFooter {
    width: 90%;
  }
}
