.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;

  margin: 3em auto 1em;
}

.messageContainerItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28em;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20em;
}

.imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.textDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12em;
}

.textDiv span:first-child {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
}

@media screen and (min-width: 683px) {
  .messageContainerItem {
    height: 30em;
  }

  .imgContainer {
    height: 23em;
  }

  .imgContainer img {
    object-position: 0% 60%;
  }
}

@media screen and (min-width: 1100px) {
  .messageContainerItem {
    flex-direction: row;
    height: 27em;
    margin: 1.5em 0;
  }

  .messageContainerItem:last-child {
    flex-direction: row-reverse;
  }

  .imgContainer {
    height: 100%;
    width: 80%;
  }

  .imgContainer img {
    object-position: 0% 60%;
  }

  .textDiv span:last-child {
    margin-top: 3em;
  }
}
