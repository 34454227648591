.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000 !important;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10000;
  transform: translate(-50%, -50%);
  z-index: 1001 !important;
  width: 90%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 3em;
}

.form input {
  margin-bottom: 10px;
  font-size: 0.8em;
  padding: 4px 9px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form button {
  margin-top: 10px;
  padding: 5px 10px;
  width: 50%;
  border: none;
  border-radius: 5px;
  background-color: #f1356d;
  color: #fff;
  font-size: 0.8em;
  cursor: pointer;
}
.form button[name="dismiss"] {
  border: solid 1px #ccc;
  background-color: white;
  color: black;
}
