.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 247, 237);
  overflow: hidden !important;
}

.loginForm {
  border-radius: 6px;
  z-index: 34;
  margin: auto;
  height: auto;
}

.loginFormHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  z-index: 4;
  position: relative;
}

.backArrow {
  position: absolute;
  top: 0em;
  left: 1em;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 1;
}

.loginFormContent {
  height: auto;
  width: 100vw;
  border-radius: 6px;
  z-index: 1;
  margin: auto;
}

.screenButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  background-color: #f2f2f2;
  padding: 0.3em;
  border-radius: 6px;
  margin-bottom: 0.5em;
  z-index: 1;
  margin: auto;
}

.screenButtonsContainer button {
  height: 40px;
  width: 45%;
  border-radius: 4px;
  font-weight: 400;
  cursor: pointer;
  border: none;
  color: #b7b7b7;
  background-color: inherit;
  z-index: 1;
}

.activeScreenButton {
  background-color: white !important;
  color: #000 !important;
  font-weight: 600 !important;
}

.flip {
  -webkit-animation: flip 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

.shape {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
}

.shape svg {
  transform: rotateX(180deg);
  height: 40em;
  border-radius: 6px;
}

.alternativeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.alternateTopDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2em;
}

.alternateTopDiv span {
  font-size: 0.9em;
  color: #989898;
  margin: 0 0.5em;
}

.divider {
  width: 23%;

  height: 1px;
  background-color: #b7b7b7;
  margin: 0 auto;
}

.icon {
  margin: 0 0.5em;
  padding: 0.7em;
  border-radius: 50%;
}

.icon:hover {
  cursor: pointer;
}

.badgeContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4em;
}

.forgot {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  color: #989898;
  text-decoration: underline;
  width: 100%;
  margin: 0.5em 0;
  font-size: 0.9em;
  cursor: pointer;
  border: none;
  background-color: rgb(255, 247, 237);
  width: auto;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error {
  color: red;
  font-size: 0.9em;
  margin: 0.5em 0;
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
    transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) rotateY(-180deg);
    transform: translateX(100%) rotateY(-180deg);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
}
@keyframes flip {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
    transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) rotateY(-180deg);
    transform: translateX(100%) rotateY(-180deg);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
}

@media screen and (min-width: 638px) {
  .screenButtonsContainer {
    width: 80%;
  }

  .alternateTopDiv {
    width: 80%;
  }

  .loginFormHeader {
    width: 80%;
  }
}

@media screen and (min-width: 800px) {
  .screenButtonsContainer {
    width: 70%;
  }

  .alternateTopDiv {
    width: 70%;
  }

  .loginFormHeader {
    width: 70%;
  }
}

@media screen and (min-width: 1000px) {
  .screenButtonsContainer {
    width: 40%;
  }

  .alternateTopDiv {
    width: 40%;
  }

  .loginFormHeader {
    width: 40%;
  }
}
