.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(40, 162, 255);
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  height: 20em;
  border-radius: 10px;
}

.text {
  font-size: 20px;
  margin-bottom: 3em;
  font-weight: 600;
  width: 80%;
  color: white;
}

.text:last-of-type {
  font-size: 16px;
  font-weight: 400;
}

.continue {
  background-color: white;
  color: rgb(40, 162, 255);
  border: none;
  border-radius: 4px;
  padding: 6px 40px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 2em;
}
