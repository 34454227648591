.container {
  height: 2.5em;
  width: 100%;
  background-color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75em;
  position: relative;
}

.x {
  position: absolute;
  right: 5px;
  top: 2px;
}
