.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 18em;
  margin: 1em 0 1em;
  border-radius: 4px;
}

.messageContainerItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 90%;
  margin: 0 0.5em;
  border-radius: 6px;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.messageContainerItem span {
  width: 95%;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60%;
}

.imgContainer img {
  height: 100%;
  object-fit: cover;
}

.imgContainer img[name="ingredients"] {
  height: 100%;
  margin-right: 0.6em;
  width: 90%;
}

.textDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.widerImgContainer {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

@media screen and (min-width: 863px) {
  .container {
    height: 16em;
  }

  .messageContainerItem {
    flex-direction: row;
    height: 14em;
  }

  .imgContainer {
    width: 12em;
    justify-content: flex-start;
    height: 70%;
    border-radius: 0 12px 12px 0;
  }

  .imgContainer img {
    width: 80%;
  }

  .imgContainer img[name="ingredients"] {
    height: 100%;
    width: 100%;
    overflow: hidden !important;
  }

  .textDiv span:last-child {
    margin: 1.2em auto 0;
  }
}

@media screen and (min-width: 1100px) {
  .containerItem {
    width: 95%;
  }

  .imgContainer img[name="ingredients"] {
    height: 100%;
    width: 100%;
    overflow: hidden !important;
  }

  .textDiv {
    width: 55%;
  }

  .textDiv span {
    width: 95%;
  }

  .textDiv span:first-child {
    margin-bottom: 1.2em;
    font-size: 1.4em;
  }
}
