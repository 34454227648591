.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0em auto;
  padding: 0 0.6rem;
  height: 90%;
  overflow-y: scroll !important;
}

.bodyHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  min-height: 2em;
  max-height: 7em;
  margin: 1em auto 0;
  font-size: 0.9em;
  border-radius: 4px;
  font-weight: 700;
}

.bodyHeaderItem:last-child {
  font-weight: 600;
  font-size: 0.9em;
  color: #42a4ff;
  margin-bottom: 0.3em;
}

.bodyHeaderTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 2em;
  border-radius: 4px;
  /* margin: 0 0 0em; */
}

.batchActions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 4em;
  border-radius: 4px;
  margin: 0 auto 0em;
  background-color: white;
  font-size: 0.8em;
  font-weight: 700;
  color: black;
  border: solid 1px rgb(230, 230, 230);
  box-shadow: 0px 5px 15px -3px rgba(0, 0, 0, 0.1);
}
