.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 97%;
  max-height: 100%;
  border-radius: 4px;
  margin: 2em auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.5em;
  border-radius: 4px;
  color: white;
  font-weight: 800;
}

.headerItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5em 1em;
  border: solid 1.2px #ffda44;
  border-radius: 4px;
  margin: 0 auto 0 0;
  width: 50%;
  color: white;
  background-color: black;
  font-weight: 800;
}

.headerItem:last-child span:first-child {
  color: rgb(255, 14, 86);
}

.headerItem svg {
  display: block;
}

.headerItem span:first-child {
  font-size: 0.6em;
  text-transform: uppercase;
  font-weight: 800;
  color: #ffd92f;
  margin-bottom: 0.3em;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  margin: 0em 0 1em;
  max-height: 32em;
  overflow-y: scroll !important;
  box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.2);
}

.bodyHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  height: auto;
  max-height: 7em;
  margin: 2em auto 0;
  font-size: 0.9em;
  border-radius: 4px;
  font-weight: 700;
}

.bodyHeaderItem:last-child {
  font-weight: 600;
  font-size: 0.9em;
  color: #42a4ff;
  margin-bottom: 0.3em;
}

.bodyHeaderTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3em;
  border-radius: 4px;
  margin: 0 0 0.5em;
}

.batchActions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 4em;
  border-radius: 4px;
  margin: 0 auto 0em;
  background-color: white;
  font-size: 0.8em;
  font-weight: 700;
  color: black;
  border: solid 1px rgb(230, 230, 230);
  box-shadow: 0px 5px 15px -3px rgba(0, 0, 0, 0.1);
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 0 0em 0 0;
  border-radius: 4px;
  margin: 0 0 0 0em;
  background-color: white;
  font-size: 1em;
  font-weight: 700;
}

.action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.3em 1em;
  font-size: 1.2em;
  border-radius: 4px;
  cursor: pointer;
}

.action:hover {
  background-color: #39ca5a;
  color: white;
}

.action:last-of-type:hover {
  background-color: #ff0e56;
  color: white;
}

span[name="selected"] {
  color: #ff0e56;
  margin-left: 0.5em;
}

.snackbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12em;
  height: 2.3em;
  position: fixed;
  bottom: 5px;
  left: 5px;
  background-color: #39ca5a;
  color: white;
  font-weight: 700;
  border-radius: 4px;
  z-index: 100;
  font-size: 0.9em;
}
