.container {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 50px;
  width: 97%;
  height: 90%;
  margin: 1em auto;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  margin: 0 auto;
  overflow-y: scroll !important;
  box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.2);
}

.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 2em;
  margin: 0 auto 2em;
}

.searchContainer h4 {
  width: 65%;
  margin: 0;
}

.search {
  display: flex;
  gap: 1em;
  align-items: center;
  flex-direction: row;
  width: 27em;
  height: 1.7em;
}

.search input {
  width: 60%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 1em;
  outline: none;
  font-size: 0.8em;
}

.search input::placeholder {
  font-size: 0.85em;
}

.searchIcon {
  width: 10%;
  height: 100%;

  cursor: pointer;
  padding: 3px;
}

.searchIcon:hover {
  border: solid 1.4px #ccc;
  border-radius: 3px;
}
