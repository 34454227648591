.container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-bottom: 3em;
}

.heroContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 37em;
  position: relative;
  margin-bottom: 1em;
}

.imgContainer {
  display: flex;
  justify-content: center;
  height: 23em;
  width: 95%;
  margin: 0 auto 3em;
}

.imgContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  object-position: bottom;
}

.heroBox {
  height: 16em;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 290px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden !important;
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
}

.heroBoxHidden {
  transform: translateX(-100%);
}

.heroText {
  display: flex;
  width: 80%;
  flex-direction: column;
  z-index: 33;
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
}

.heroTextHidden {
  transform: translateX(-100%);
}

.headline {
  width: 70%;
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 0.2em;
  line-height: 50px;
}

.subHeadline {
  font-size: 1.1em;
  margin-bottom: 0.5em;
  color: rgb(63, 63, 63);
}

.exploreButton {
  width: 10em;
  height: 2.5em;
  background-color: black;
  border: none;
  color: white;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 1em;
}

.qualityImgContainer {
  display: flex;
  justify-content: center;
  height: 25em;
  width: 95%;
  margin: 0 auto 3em;
  position: relative;
  border-radius: 6px;
}

.qualityImgContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
  object-position: bottom;
}

.imgOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    -0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 1;
}

.imgText {
  position: absolute;
  bottom: 0%;
  font-family: var(--primaryfont);
  width: 80%;
  color: white;
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 3em;
}

.badgeDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 97%;
  height: 12em;
  padding: 1em 1em;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden !important;
  margin-bottom: 3em;
}

.badgeDiv img {
  height: auto;
  width: 4em;
  z-index: 1;
}

.badgeDiv span {
  font-size: 1em;
  font-weight: 600;
  margin-left: 1em;
  color: rgb(42, 42, 42);
  text-align: center;
  z-index: 1;
}

.shade {
  position: absolute;
  height: 30em;
  width: 30em;
  left: -20px;
  bottom: 0px;
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
}

.shadeHidden {
  transform: translateX(-100%);
}

.secondBadgeDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 97%;
  height: 12em;
  padding: 1em 1em;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: 3em;
}

.secondBadgeDiv img {
  height: auto;
  width: 4em;
  z-index: 1;
}

.secondBadgeDiv span {
  font-size: 1em;
  font-weight: 600;
  margin-left: 1em;
  color: rgb(42, 42, 42);
  text-align: center;
  z-index: 1;
}

.shadeSecond {
  position: absolute;
  height: 30em;
  width: 30em;
  right: -180px;
  bottom: -120px;
  transform: rotate(270deg);
}

.container > h3[name="highlyRated"] {
  align-self: flex-start;
  font-size: 1.5em;
  font-weight: 600;
  margin-left: 1em;
}

.viewAllDiv {
  display: flex;
}

.viewAllDiv span {
  font-size: 0.9em;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  margin-left: 1.8em;
}

.featuredContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 95%;
  margin: 2em auto 2em;
}

.imageAndMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 2em;
}

.loading {
  filter: blur(10px);
  clip-path: inset(0);
}

.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

@media screen and (min-width: 600px) {
  .imgContainer {
    display: flex;
    justify-content: center;
    height: 23em;
    width: 95%;
    margin: 0 auto 3em;
  }

  .imgContainer img {
    height: auto;
    overflow: hidden !important;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    object-position: 0% 80%;
  }
}

@media screen and (min-width: 863px) {
  .imageAndMessage {
    display: flex;
  }

  .qualityImgContainer {
    margin: 2em auto 3em;
    height: 35em;
  }

  .imgText {
    font-size: 1.5em;
    margin-top: 1.5em;
  }
}

@media screen and (min-width: 890px) {
  .imgContainer {
    display: flex;
    justify-content: center;
    height: 26em;
    width: 100%;
    margin: 0 auto 3em;
  }

  .imgContainer img {
    height: auto;
    overflow: hidden !important;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    object-position: 0% 50%;
  }

  .heroBox {
    width: 80%;
  }
}

@media screen and (min-width: 1100px) {
  .imgContainer {
    display: flex;
    justify-content: center;
    height: 32em;
    width: 100%;
    margin: 0 auto 3em;
  }

  .featuredContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 97%;
    margin: 2em auto 0;
    /* gap: 8em; */
  }

  .imgContainer img {
    height: auto;
    overflow: hidden !important;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    object-position: 0% 20%;
  }

  .imageAndMessage {
    margin: 2em;
  }

  .heroBox {
    top: 320px;
    z-index: 999;
    width: 60%;
  }

  .imgText {
    font-size: 1.8em;
    margin: 0;
  }

  .heroContainer {
    height: 38em;
  }

  .container > h3[name="highlyRated"] {
    align-self: flex-start;
    font-size: 1.5em;
    font-weight: 600;
    margin-left: 1.5em;
  }

  .viewAllDiv span {
    margin-left: 2.5em;
  }
}
