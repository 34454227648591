.divContainer {
  max-width: 97%;
  align-items: center;
  width: 97%;
  margin: 0em auto 3em;
  height: 26em;
  color: black;
  margin-bottom: 3em;
}

.divContainer span[name="title"] {
  font-size: 1.5em;
  font-weight: 600;
}

.container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 15%;
  overflow-x: scroll !important;
  overscroll-behavior-inline: contain;
  grid-gap: 16em;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0em auto 3em;
  height: 90%;
  color: black;
  padding: 1em;
  margin-bottom: 3em;
}

.viewAllDiv {
  display: flex;
}

.viewAllDiv span {
  font-size: 0.9em;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.snap {
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: 1em;
}

.snap > * {
  scroll-snap-align: start;
}

@media screen and (min-width: 600px) {
  .container {
    grid-auto-columns: 35%;
    grid-gap: 10em;
  }
}

@media screen and (min-width: 700px) {
  .container {
    grid-auto-columns: 25%;
    grid-gap: 10em;
  }
}

@media screen and (min-width: 862px) {
  .container {
    grid-auto-columns: 35%;
    grid-gap: 2em;
  }
}

@media screen and (min-width: 1100px) {
  .container {
    grid-auto-columns: 30%;
    grid-gap: 0em;
  }
}
