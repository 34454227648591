.container {
  height: 21em;
  width: 95%;
  margin: 1em 0 1em;
  background-color: #151515;
  color: white;
  border-radius: 6px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 80%;
  margin: auto;
}

.contentContainer span:first-child {
  font-size: 1.8em;
  font-weight: 600;
  text-align: center;
}

.contentContainer span:last-child {
  font-size: 1.1em;
  font-weight: 400;
  text-align: center;
  margin-top: 2em;
  color: rgb(104, 104, 104);
}
