.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2em auto 0em;
  width: 97%;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.sort {
  align-self: flex-start;
  align-items: center;
  display: flex;
  margin: 0em auto 0.5em;
  width: 97%;
  cursor: pointer;
}

.sort span {
  font-size: 0.8em;
  font-weight: 600;
  border-radius: 24px;
  align-self: center;
  margin-left: 1em;
}

.sortDiv {
  display: flex;
  margin-left: auto;
}

.sortItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: solid 1px rgb(217, 217, 217);
  margin: 0 0.5em;
  cursor: pointer;
  padding: 0.3em 1.2em;
  font-size: 0.7em;
  font-weight: 700;
  color: rgb(105, 105, 105);
  text-transform: uppercase;
  transition: all 0.15s ease-in-out;
  border-radius: 4px;
}

.sortItem:last-child {
  margin-right: 0;
}

@media screen and (min-width: 1100px) {
  .body {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
    flex-wrap: wrap;
  }

  .sort {
    margin: 0em 0 1em 1.5em;
  }
}
